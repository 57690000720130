import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import ContentWrapper from "../components/ContentWrapper";
import { Link } from "gatsby";

const MainWrapper = styled.div`
  padding-top: 65px;
  @media screen and (max-width: ${props => props.theme.responsive.large}) {
    padding-top: 63px;
  }
  .breadcrumbs {
    background: #ececec;
    padding: 19px 0;
    min-height: 47px;
  }
  .breadcrumbs-list {
    font-size: 14px;
    line-height: 1;
  }
  .breadcrumbs-item {
    float: left;
    margin: -2px 6px 0 0;
    &:nth-child(n+2) {
      &::before {
        content: ">";
        padding: 0 5px 0 0;
      }
    }
  }
  .breadcrumbs-item-link {
    color: #555;
    &:hover {
      text-decoration: underline;
    }
  }
  .article-inner {
    margin: 100px 0;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      margin: 15px 0;
    }
  }
  .article-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 0;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      font-size: 1.3rem;
      text-align: left;
    }
  }
  .article-date {
    text-align: right;
    font-size: 0.9rem;
  }.article-body {
    p {
      margin-top: 1rem;
      letter-spacing: normal;
      strong {
        font-weight: bold;
      }
      em {
        font-size: italic;
      }
      s {
        text-decoration: line-through;
      }
    }
    p+p {
      margin-top: 1.2rem;
    }
    h1+p, h2+p, h3+p, h4+p, h5+p, h6+p {
      margin-top: 0.3rem;
    }
    a {
      color: #331cbf!important;
      &:hover {
        text-decoration: underline;
      }
    }
    h1, h2, h3, h4, h5, h6 {
      font-weight: bold;
    }
    h1, h2, h3 {
      margin-top: 2.3rem;
      margin-bottom: 0.7rem;
    }
    h4, h5, h6 {
      margin-bottom: 1rem;
    }
    h1 {
      font-size: 1.7rem;
      @media screen and (max-width: ${props => props.theme.responsive.large}) {
        font-size: 1.6rem;
      }
    }
    h2 {
      font-size: 1.6rem;
      @media screen and (max-width: ${props => props.theme.responsive.large}) {
        font-size: 1.5rem;
      }
    }
    h3 {
      font-size: 1.3rem;
      @media screen and (max-width: ${props => props.theme.responsive.large}) {
        font-size: 1.2rem;
      }
    }
    h4 {
      font-size: 1.15rem;
      @media screen and (max-width: ${props => props.theme.responsive.large}) {
        font-size: 1.1rem;
      }
    }
    h5 {
      font-size: 1rem;
    }
    h6 {
      font-size: 0.9rem;
      @media screen and (max-width: ${props => props.theme.responsive.large}) {
        font-size: 0.85rem;
      }
    }
    ol, ul {
      margin: 1rem 0;
    }
    ol>li, ul>li {
      margin: 0.6rem 0;
    }
    ul {
      padding-left: 1.8rem;
      &>li {
        list-style: disc;
        &::marker {
          font-size: 1.1rem;
          color: #5e6478;
        }
      }
    }
    ol {
      padding-left: 1.7rem;
      &>li {
        list-style: decimal;
        padding-left: 0.2rem;
        &::marker {
          color: #535872;
          font-weight: 600;
          letter-spacing: -0.05rem;
        }
      }
    }
    img {
      margin: 1.5rem auto;
      display: table;
      max-width: 100%;
      height: auto;
      &~em {
        display: block;
        margin: -0.5rem auto 0;
        line-height: 1.3;
        text-align: center;
      }
    }
    blockquote {
      font-size: 0.97rem;
      margin: 1.4rem 0;
      border-left: 3px solid #9dacb7;
      padding: 2px 0 2px 0.7rem;
      color: #505c64;
      p {
        margin: 1rem 0;
      }
      &>:first-child {
        margin-top: 0;
      }
      &>:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export default function Post({ pageContext }) {
  const { newsId, createdAt, title, body } = pageContext.post;

  return (
    <Layout>
      <Seo title={title} description="" />
      <MainWrapper>
        <div className="breadcrumbs">
          <ContentWrapper>
            <ol className="breadcrumbs-list">
              <li className="breadcrumbs-item">
                <Link to={`/`} className="breadcrumbs-item-link">ホーム</Link>
              </li>
              <li className="breadcrumbs-item">
                <Link to={`/news`} className="breadcrumbs-item-link">ニュース</Link>
              </li>
              <li className="breadcrumbs-item">
                <Link to={`/news/${newsId}`} className="breadcrumbs-item-link">{createdAt}</Link>
              </li>
            </ol>
          </ContentWrapper>
        </div>
        <ContentWrapper>
          <div className="article-inner">
            <h1 className="article-title">{title}</h1>
            <p className="article-date">{createdAt}</p>
            <div dangerouslySetInnerHTML={{ __html: body }} className="article-body" />
          </div>
        </ContentWrapper>
      </MainWrapper>
    </Layout>
  );
};